


























import Vue from 'vue';
import AppIcon from '@/components/AppIcon.vue';
import { ROUTE_STATUSES, DELIVERY_STATUSES } from '@/utils/constants';

export default Vue.extend({
  name: 'AppPanelHeader',
  components: {
    AppIcon,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    isMdiIcon(): boolean {
      return this.icon.includes('mdi');
    },
    hasStatus(): boolean {
      return !!this.status && !!this.statusIcon;
    },
    statusIcon(): string {
      const icons = {
        [ROUTE_STATUSES.STARTED as string]: 'mdi-dots-horizontal',
        [ROUTE_STATUSES.COMPLETED as string]: 'mdi-check',
        [DELIVERY_STATUSES.COMPLETED as string]: 'mdi-check',
      };
      return icons[this.status];
    },
    statusColor(): string {
      const colors = {
        [ROUTE_STATUSES.STARTED as string]: 'warning',
        [ROUTE_STATUSES.COMPLETED as string]: 'success',
        [DELIVERY_STATUSES.COMPLETED as string]: 'success',
      };
      return colors[this.status];
    },
  },
});
