






import Vue from 'vue';

export default Vue.extend({
  name: 'AppPanel',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
