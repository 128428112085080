import { render, staticRenderFns } from "./AppPanelItem.vue?vue&type=template&id=112218ae&scoped=true&"
import script from "./AppPanelItem.vue?vue&type=script&lang=ts&"
export * from "./AppPanelItem.vue?vue&type=script&lang=ts&"
import style0 from "./AppPanelItem.vue?vue&type=style&index=0&id=112218ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112218ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VList})
