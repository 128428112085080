

























import Vue from 'vue';
import AppPanelHeader from '@/components/AppPanelHeader.vue';

export default Vue.extend({
  name: 'AppPanelItem',
  components: {
    AppPanelHeader,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
});
